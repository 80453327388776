
.App {
  text-align: center;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  font-family: 'Nunito';
  background-color: #17271E;
}

body {background-color: #17271E;overflow: hidden;}

.bg-main{
  background-color: #17271E;
}
.main{
  color:  #17271E;
}
.main-drawer div {

  background-color: #17271E;
}

.nunito {
  font-family: "Nunito";
}
.transitioning{
  transition: width 300ms;
}
.transaciton-bottom{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(23, 39, 30, 0.69) 100%);

}
.pie-chart .recharts-legend-item-text {
  font-size:12px !important;
}

.refund {
  background-color: #FF0000;
}

.bottom-height {
  height: -o-calc(100vh - 158px); /* opera */
  height: -webkit-calc(100vh - 158px); /* google, safari */
  height: -moz-calc(100vh - 158px); /* firefox */
  height: calc(100vh - 158px);
}
.table-wrapper {
  height: -o-calc(100vh - 218px); /* opera */
  height: -webkit-calc(100vh - 218px); /* google, safari */
  height: -moz-calc(100vh - 218px); /* firefox */
  height: calc(100vh - 218px);
}
.bottom-height-no-toolbar {
  height: -o-calc(100vh - 78px); /* opera */
  height: -webkit-calc(100vh - 78px); /* google, safari */
  height: -moz-calc(100vh - 78px); /* firefox */
  height: calc(100vh - 78px);
}

.style-2::-webkit-scrollbar {
  width: 0px;
}
.style-1 {
  scrollbar-color: #B9EA25 white;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  
}
.scroll-space {
  margin-left: 6px;
}

.style-2{
  scrollbar-width: none;
}

.style-1::-webkit-scrollbar {
	width: 6px;
	background-color: white;;
}

.style-1::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 250px; 
}
.style-1::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 50px;

  border-radius: 0.5rem;
}

.style-1::-webkit-scrollbar-thumb {
	background-color: #B9EA25;
  border-radius: 0.5rem;
}

.float-label {
  position: relative;
  margin-bottom: 12px;
}

.label {
  background-color: #17271E;
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: -2px;
  transition: 0.2s ease all;
  color:#B9EA25;
}

.label-float {
  width: auto;
  padding: 0 6px;
  font-size: 12px;
}
.fade-in-image {
  animation: fadeIn 400ms;
  -webkit-animation: fadeIn 400ms;
  -moz-animation: fadeIn 400ms;
  -o-animation: fadeIn 400ms;
  -ms-animation: fadeIn 400ms;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.nice-font {
  font-family: 'Titillium Web', sans-serif;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #002aff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.full-shadow {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.StripeElement--invalid{
  border:1px solid red !important;
}
.full-radius {
  border-radius: 50%;
}
#video-iframe{
width:80%;
height: 90vh;
display:inline-block;
}


@media (max-width: 756px) {
  #video-iframe{
    width:100%;
    height: 100vh;
    display:block;
    }
}
.dark {
  background-color: #333035;
}
.bg-purple {
  background-color:#6a60bb
}
.purple {
  color: #6a60bb
}
.dropdown-caret {
  border-bottom: 0 solid transparent; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #17271E;
  content: "";
  display: inline-block;
  height: 0;
  vertical-align: middle;
  width: 0;
}
.dropdown-menu:before {
  border: 8px solid transparent;
  border-bottom: 8px solid #17271E;
  content: "";
  display: inline-block;
  position: absolute;
  left: auto;
  right: 15px;
  top: -16px;
}
.dropdown-menu-left:before {
  border: 8px solid transparent;
  border-bottom: 8px solid #17271E;
  content: "";
  display: inline-block;
  position: absolute;
  left: 15px;
  right: auto;
  top: -16px;
}
.bg-green-fade {
  background-color: #525A3B !important;
  
}
.refunded {
  background-color: #FF0000  ;
}
.completed{
  background-color: #B9EA25  ;
}
.pending{
  background-color: #ffffff  ;
}
.MuiDialog-paperWidthSm{
  max-width: 100vw !important;
}
.pending_refund{
  background-color:#ee4407;
}
.add-user-input::placeholder{
  color: #FFFFFF
}
.placeholder-small::placeholder{
  font-size: 12px;
  color: #FFFFFF !important;
}
.PhoneInputInput{
  background-color: #17271E;
  outline: none;
  color: #B9EA25;
} 
.PhoneInputInput::placeholder{
  color: #B9EA25;
}
.MuiDialog-paperScrollPaper {
  background-color: #17271E !important;
  border-radius: 20px !important;
}
.login-input::placeholder {
  color: black
}
.date-picker > div{ 
  border:none !important;
  color: #B9EA25
}

.react-date-picker__inputGroup__input::placeholder{
  color:#B9EA25;
}

.react-date-picker__clear-button{
  background-color: #B9EA25;  
}

.font-16{
  font-size: 16px !important;
}
.radio-active {
  accent-color:#B9EA25;  
}

.radio-inactive {
  accent-color:#FF9900
}

.orage {
  color:#FF9900
}

.normal-size {
  font-size: 14px;
}

.pending {
  background-color: #FFFFFF;
  color: #FF0000;
}

.file-uploader label {
  border: none !important;
}

.full-width {
  width: 100% !important;
}
.descrip-prod::placeholder {
  color: #B9EA25;
}

.drawer-opener{
  border:1px !important;
  border-style: solid !important;
  border-color: #17271E;
  position: absolute !important;
}
.page-selected {
  background-color: #5151F9 !important;
  color:white;
}
.bg-blue{
  background-color: #5151F9;
}
.checkbox {
display: none;
}

.checkbox-span {
  visibility: visible;
  content: "";
  display: block;
  width: 1.1em;
  height: 1.1em;
  color: white;
  background-color: #B9EA25;
  border-radius: 10%;
  line-height: 1.27;
  text-align: center;
  cursor: pointer;
}

.checkbox:checked + label span::before {
  background-image: url('../public/lime-dark.svg');
    background-size: 10px 20px;
    display: inline-block;
    width: 10px; 
    height: 20px;
    content:"";
  
}

.checkbox:before + label span{
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  border-radius: 3px;
  background-color: #B9EA25;
}

.grayed {
  background-color: #525252 !important;
}
.checkbox:checked:after {
  content: "";
  display: block;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 3px;
  left: 6px;
}
.no-shadow{
  box-shadow: none !important;
  border-radius: 0 !important;
}

.border-dashed {
  background-image: linear-gradient(to right, #B9EA25 50%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 15px 1px;
  background-repeat: repeat-x;
}
.no-border {
  border:none !important
}
.fiver-font {
  font-family: macan,helvetica neue,Helvetica,Arial,sans-serif;
}
.orange {
  color: #fd6328
}
.border-green {
  border-color: #B9EA25 !important;
}
.border-yellow {
  border-color: #FFCF25 !important;
}

.border-main {
  border-color: #17271E !important;
}
.green {
  color:#B9EA25 !important;
}
.bg-green {
  background-color: #B9EA25 !important;
}
.bg-yellow {
  background-color: yellow !important;
}
.bg-orange {
  background-color: #FF9900 !important;
}
.bg-red {
  background-color: #FF0000 !important;
}
.bg-gray {
  background-color: #C8C8C8 !important;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}
.rotate {
  animation: rotation 3s infinite linear;
}
.policy p{
  text-align: left !important;
}

.policy div{
  text-align: left !important;
}

.policy li{
  text-align: left !important;
}
#svg_cross {
  width: 100px;
  display: block;
  margin: 40px auto 0;
}
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.landing {
  margin-bottom: -200px;
  padding-bottom: 80px;
}
.delete-button{ 
  background-color: red !important;
  width: 120px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}
.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}
.rdw-editor-wrapper{
  width: 100% !important;
}
.path.line {
  stroke-dashoffset: 1000;
  animation: dash 2s 0.35s ease-in-out forwards;
  -webkit-animation: dash 2s 0.35s ease-in-out forwards;
}
.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}
p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
}
.rdw-link-modal{
  height: 235px !important;
}
.create-form a {
  color: blue;
}
p.success {
  color: #73af55;
}
p.error {
  color: #d23f3f;
  font-weight: bold;
  margin-top:20px;
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
.red {
  color:#D06079
}
.blue {
  color:#022c46
}

/*
* 17. about styles
*/

.about-one__circle {
  position: absolute;
  right: 10%;
  bottom: -3%;
}
.about-one__img {
  position: relative;
  margin-bottom: 45px;
}

.about-one__text {
  margin: 0;
  font-size: 18px;
  line-height: 36px;
  margin-bottom: 60px;
}
.about-one__btn {
  padding: 21.5px 68px;
  font-size: 16px;
}
.about-one__btn:hover {
  background-color: #012237;
  color: #fff;
}
.about-one__review {
  background-color: #fff;
  max-width: 300px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 35%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 33.5px 0;
}
.about-one__review-count {
  margin: 0;
  font-weight: 400;
  font-size: 30px;
  color: #012237;
  line-height: 1em;
}
.about-one__review-stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1em;
  margin-top: 10px;
  margin-bottom: 10px;
}
.about-one__review-stars i {
  color: #f16101;
  font-size: 20px;
}
.about-one__review-stars i + i {
  margin-left: 10px;
}
.about-one__review-text {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .2em;
}
.about-one__about-page {
  border-bottom: 1px solid #f1f1f1;
}
.about-one__about-page .about-one__img:before {
  top: auto;
  bottom: -40%;
}

/*
* 4. contact styles
*/
.google-map__contact {
  display: block;
  width: 100%;
  height: 560px;
  border: none;
  outline: none;
}

.contact-info-one {
  padding-top: 120px;
}
.contact-info-one__title {
  margin: 0;
  color: #012237;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 55px;
}
.contact-info-one__text {
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  font-weight: 500;
  color: #81868a;
  margin-top: -15px;
  margin-bottom: -10px;
}
.contact-info-one__icon {
  width: 112px;
  height: 112px;
  background-color: #f16101;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin-bottom: 25px;
  position: relative;
}
.contact-info-one__icon::before {
  content: '';
  width: 100%;
  height: 100%;
  border: 2px solid #120a3d;
  position: absolute;
  top: 0;
  left: -10px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  border-radius: 50%;
}
.contact-info-one__icon i {
  font-size: 54px;
  color: #fff;
}

.contact-info-two::before {
  border-color: rgb(7, 29, 17);
}
.contact-info-three::before {
  border-color: #412854;
}


.secondary-bg {
  background-color:#EEA47FFF;
}

.secondary-border {
  border-color: #EEA47FFF;
}

.secondary-text {
  color: #EEA47FFF;
}

.main-text {
  color:black
}

.un {
  display: inline-block;
}

.un::after {
  content: '';
  width: 0px;
  height: 1px;
  display: block;
  background: white;
  transition: 300ms;
}

.un:hover::after {
  width: 100%;
}

.force-hidden {
  visibility: hidden !important;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear; 
}
.force-show {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}
.contact-info-one__single {
  border-style: solid;
  height:100%;
  border-width: 2px;
  border-color: #f1f1f1;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: -webkit-box-shadow .4s ease;
  transition: -webkit-box-shadow .4s ease;
  transition: box-shadow .4s ease;
  transition: box-shadow .4s ease, -webkit-box-shadow .4s ease;
  padding-top: 48px;
  padding-bottom: 48px;
}
.contact-info-one__single:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}
.contact-info-one__single:hover .contact-info-one__icon:before {
  left: 0;
}

.contact-one {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (min-width: 1200px) {
  .contact-one .container {
    max-width: 800px;
  }
}
.contact-one__title {
  font-size: 60px;
  font-weight: bold;
  color: #012237;
  margin: 0;
  margin-bottom: 45px;
}
.contact-one input,
.contact-one textarea {
  border: none;
  outline: none;
  width: 100%;
  display: block;
  background-color: #f1f1f1;
  height: 77px;
  padding-left: 40px;
  margin-bottom: 10px;
}
.contact-one textarea {
  height: 211px;
  padding-top: 25px;
}
.contact-one .row.low-gutters {
  margin-left: -5px;
  margin-right: -5px;
}
.contact-one .row.low-gutters > [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}
.contact-one__btn {
  padding: 22px 72px;
}
.contact-one__btn:hover {
  background-color: #012237;
  color: #fff;
}
.duration-900{
  transition-duration: 900ms !important;
}
.w-750{
  width:750px
}
ul {
  list-style-type: square;
}
ul > li > ul {
  list-style-type: circle;
}
ul > li > ul > li > ul {
  list-style-type: square;
}
ol li {
  font-family: Arial ;
}
  .post-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .post-pagination a {
    border-radius: 50%;
    background-color: #bbbaba;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    padding: 8px 19px;
    color: #230937;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  .post-pagination a:hover, .post-pagination a.active {
    background-color: #230937;
    color: #fff;
  }
  .post-pagination a + a {
    margin-left: 15px;
  }
  .dot-elastic {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: black;
    color: black;
    animation: dotElastic 1s infinite linear;
  }
  
  .dot-elastic::before,
  .dot-elastic::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
  .dot-elastic::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: black;
    color: black;
    animation: dotElasticBefore 1s infinite linear;
  }
  
  .dot-elastic::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: black;
    color: black;
    animation: dotElasticAfter 1s infinite linear;
  }
  
  @keyframes dotElasticBefore {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes dotElastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 1.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes dotElasticAfter {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  .stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem 0;
    margin: 0 -5%;
    overflow: hidden;
  }
  